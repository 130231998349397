<script>
// Extensions
import View from "@/views/View";

// Mixins
import LoadSections from "@/mixins/load-sections";

export default {
  name: "OurStory",

  metaInfo: { title: "OUR STORY" },

  extends: View,

  mixins: [LoadSections(["hero-alt", "Ourstory", "Brands", "contact-us"])],

  props: {
    id: {
      type: String,
      default: "our-story"
    }
  }
};
</script>
